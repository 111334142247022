@import './../../../../theme/theme';

.routeRedeemModal {
  background-color: $backgroundColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0px;
  outline: none;
  border-radius: 8px;
  position: relative;
  max-width: 400px;
  min-height: 275px;
  min-width: 438px;
  padding: 20px 56px;
  .redeemHeading {
    font-weight: bold;
  }
}

.redeemCodeButtonContainer {
  padding-top: 15px;
}

.crossDiv {
  position: absolute;
  top: 11px;
  left: 400px;
}

.crossWidth {
  width: 17px;
  cursor: pointer;
}

.redeemTitle {
  font-weight: bold;
}

.inputField {
  margin-top: 10px;
  min-width: 208px;
}

.note {
  padding: 5px;
  color: $primaryFontColor;
}

.formErrors {
  font-family: $primaryFontFamily;
  font-size: $fontSmall;
  color: $dangerColor;
  margin-left: 6px;
  margin-top: 6px;
  max-width: 208px;
  text-align: center;
}

.successMessage {
  font-size: 25px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: $primaryColor;
  > p {
    text-align: center;
  }
}
